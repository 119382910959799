<template>
  <button
    class="s-age-btn"
    @click="$emit('click-btn')"
  >
    {{ title }} <span>&gt;</span>
  </button>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-age-btn {
    display: flex;
    justify-content: space-around;
    background: #fff;
    border: 2px solid #3fb9c5;
    box-sizing: border-box;
    border-radius: 24px;
    width: 125px;
    height: 48px;
    font-family: DM Mono, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #002d52;

    @media screen and (max-width: 772px) {
      width: 93px;
      height: 36px;
      font-size: 12.8px;
      line-height: 16px;
    }
  }
</style>
